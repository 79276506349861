import React, { useState } from "react"
import "../../src/components/styles/pricing.scss"
import "../../src/components/styles/common/layout.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { ReactComponent as AutoBotIcon } from "../components/icons/pricing/Bots.svg"
import { ReactComponent as CollabIcon } from "../components/icons/pricing/Collaboration.svg"
import { ReactComponent as IntegrateIcon } from "../components/icons/pricing/Integrations.svg"
import { ReactComponent as VidsngifsIcon } from "../components/icons/pricing/vidsngifs.svg"
import { ReactComponent as QualityIcon } from "../components/icons/pricing/QualityChecks.svg"
import { ReactComponent as CustomIcon } from "../components/icons/pricing/Custom.svg"
import { ReactComponent as TemplateIcon } from "../components/icons/pricing/template.svg"
import { ReactComponent as ChevronIcon } from "../components/icons/pricing/chevron.svg"


const CaptureForm = ({ onSubmit, onHide }) => {
  const [emailId, setEmailId] = useState("")
  const [isSubmitted, setSubmit] = useState(false)
  return (
    <>
      <div className={"overlay"} onClick={onHide}></div>
      <div className={"capture-form"}>
        <h3>Request Access</h3>
        <img src="https://lh3.googleusercontent.com/h9nOZqKjBWG15NAqGKqpsFNgRtmk4ty406rij2pHOeoLaBWrS92kg8NEPpfXEtMcuZZ1sSQluB1aoEBcVca94eA"></img>
        {!isSubmitted && (
          <div className={"form"}>
            <input
              type="email"
              placeholder={"Your email"}
              onInput={e => {
                setEmailId(e.target.value)
              }}
            ></input>
            <button
              id={"hero-capture-form-button"}
              form="form1"
              onClick={e => {
                e.preventDefault()
                if (emailId) {
                  onSubmit(emailId, e)
                  setSubmit(true)
                }
              }}
            >
              Request Access
            </button>
          </div>
        )}
        {isSubmitted && (
          <div className={"ack"}>
            <p>🥳Great! our team will soon get back to you. </p>
            <button
              onClick={e => {
                e.preventDefault()
                onHide()
              }}
            >
              Go to Website
            </button>
          </div>
        )}
      </div>
    </>
  )
}

export default () => {
  const [details, setDetails] = useState("none")
  const [planType, setPlanType] = useState("none")
  const [quesno, setquesno] = useState("none")
  const [showCaptureForm, setCaptureForm] = useState(false)
  let width = 600;
  if (typeof window !== "undefined" && window) {
    width = window.innerWidth
  }
  console.log(width);
  const requestAccess = emailId => {
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")
    var raw = JSON.stringify({
      emailId: emailId,
      tags: ["Pricing Page", planType],
    })
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    }
    fetch(
      "https://us-central1-kubric-website.cloudfunctions.net/addVisitor",
      requestOptions
    )
  }
  return (
    <Layout mainClassName={"overflowing-bakground"}>
      <SEO
        title="Start for free | Kubric Pricing - All In One Workspace for Designers"
        description=" Select a subscription that best fits your team. Starter, Pro & Enterprise Editions"
      />
      {showCaptureForm && (
        <CaptureForm
          onSubmit={requestAccess}
          onHide={setCaptureForm.bind(undefined, false)}
        />
      )}
      <section className={"pricing-header"}>
        <div className={"content"}>
          <h1>
            Transparent pricing,<span>Free to get started </span>
          </h1>
          <p>Get started for free, pay for what you use. </p>
        </div>
      </section>
      <section className="pricing">
        <div className="pricing-table">
          <div className={"placeholder"}>
            <div className={"header invisible"} />
            <div className={"cells row"}>
              <div className={"cell"}>
                Templates
              </div>
              <div className={"cell double"}>
                Design Bots
              </div>
              <div className={"cell"}>
                Exports
              </div>
              <div className={"cell"}>
                Version History
              </div>
              <div className={"cell"}>
                Search
              </div>
            </div>
          </div>
          <div className={"starter"}>
            <div className={"header left"}>
              <div className={"plan-info"}>
                <h3>Starter</h3>
                <p>Single User Access</p>
                <div className={"plan-desc"}>Perfect for small store marketers</div>
              </div>
              <div className={"price-info"}>
                <span className={"price offer"}>
                  <span className="striker">$49</span>$0
                </span>
                <span className={"billing-info"}>Billed Monthly</span>
              </div>
              <button
                id={"starter-bttn"}
                onClick={() => {
                  setPlanType("Starter")
                  setCaptureForm(true)
                }}
              >
                Try Now!
              </button>
              <div
                className={"showmore"}
                onClick={() => {
                  setDetails(details === "starter" ? "none" : "starter")
                }}
              >
                {details === "starter" ? "Hide Details" : "Show Features"}
              </div>
            </div>

            <div className={`cells ${details === "starter" ? "show" : ""}`}>
              <div className={"cell"}>
                <div className={"feature"}>Pre-created designs</div>
              </div>
              <div className={"cell double"}>
                <div className={"feature"}>Auto-contrast Color</div>
                <div className={"feature"}>Auto Text wrap</div>
              </div>
              <div className={"cell"}>
                <div className={"feature"}>Upto 10 exports</div>
                <div className={"feature description"}>per month FREE</div>
              </div>
              <div className={"cell"}>Last 3 versions</div>
              <div className={"cell"}>Universal Search</div>
            </div>
          </div>

          <div className={"pro"}>
            <div className={"header center"}>
              <div className={"plan-info"}>
                <h3>Pro</h3>
                <p>5 User Access</p>
                <div className={"plan-desc"}>Perfect for marketers & freelancers</div>
              </div>
              <div className={"price-info"}>
                <span className={"price"}>$399</span>
                <span className={"billing-info"}>Billed Monthly</span>
              </div>

              <button
                id={"pro-bttn"}
                onClick={() => {
                  setPlanType("Pro")
                  setCaptureForm(true)
                }}
              >
                Buy Now!
              </button>
              <div
                className={"showmore"}
                onClick={() => {
                  setDetails(details === "prolist" ? "none" : "prolist")
                }}
              >
                {details === "prolist" ? "Hide Details" : "Show Features"}
              </div>
            </div>
            <div className={`cells ${details === "prolist" ? "show" : ""}`}>
              <div className={"cell"}>
                <div className={"feature"}>
                  1 custom banner design import
                </div>
              </div>

              <div className={"cell double"}>
                <div className={"feature"}>Auto Contrast Color</div>
                <div className={"feature"}>Auto Text Wrap</div>
                <div className={"feature"}>Auto Position</div>
              </div>
              <div className={"cell"}>
                <div className={"feature"}>200 exports included</div>
                <div className={"feature description"}>Top up as you need them</div>
              </div>
              <div className={"cell"}>
                <div className={"feature"}>Upto last 10 versions</div>
              </div>
              <div className={"cell"}>
                <div className={"feature"}>Integrated smart search</div>
                <div className={"feature description"}>Search across your asset sources</div>
              </div>
              <div className={"marker-pro"}>
                <div className={"cell double burble"}>
                  <div className={"feature"}>Out of the box Integrations</div>
                  <div className={"feature description"}>ShutterShock, Cloudinary, Google Drive, DropBox</div>
                </div>
                <div className={"cell clearbg"}>
                  <div className={"feature"}>5 users included</div>
                  <div className={"feature description"}>add more at $12/mo</div>
                </div>
              </div>
              <div className={"marker-addons"}>
                <div className={"cell"}>
                  + Add On Video/GIF Support
                </div>
                <div className={"cell "}>+ Add On Auto Quality Check</div>
                <div className={"cell triple"}>
                  <div className={"feature"}> + Add On Bots </div>
                  <br></br>
                  <div className={"feature bold"}> Auto Gradient </div>
                  <div className={"feature bold"}> Auto Background Remove </div>
                  <div className={"feature bold"}> Auto Resize </div>
                </div>
                <div className={`cell ${width >= 800 ? "double" : ""}`}>+ Add On Custom Templates</div>
              </div>
            </div>
          </div>
          <div className={"enterprise"}>
            <div className={"header right"}>
              <div className={"plan-info"}>
                <h3>Enterprise</h3>
                <p>5+ User Teams</p>
                <div className={"plan-desc"}>Perfect for content-first companies</div>
              </div>
              <div className={"price-info"}>
                <span className={"price"}>$1299</span>
                <span className={"billing-info"}>Billed Monthly</span>
              </div>

              <button
                id={"enterprise-bttn"}
                onClick={() => {
                  setPlanType("Enterprise")
                  setCaptureForm(true)
                }}
              >
                Contact Us
              </button>
              <div
                className={"showmore"}
                onClick={() => {
                  setDetails(details === "enterprise" ? "none" : "enterprise")
                }}
              >
                {details === "enterprise" ? "Hide Details" : "Show Features"}
              </div>
            </div>
            <div className={`cells ${details === "enterprise" ? "show" : ""}`}>
              <div className={"cell"}>
                <div className={"feature"}>Unlimited design imports</div>
              </div>
              <div className={`cell ${width >= 800 ? "double" : ""}`}>Unlimited Auto Edit Bots</div>
              <div className={"cell"}>Customized Tiers for Large scale needs</div>
              <div className={"cell"}>Unlimited Versions</div>
              <div className={"cell"}>Customizable Asset Taxonomy Builder</div>
              <div className={"cell burble double"}>
                Everything in Pro<br></br> + Build custom integrations
              </div>
              <div className={"cell "}>
                20 users included, <br></br>
                <div className={"feature description"}>Add more with custom plans</div>
              </div>
              <div className={"cell burble"}>+ Video/GIF Support</div>
              <div className={"cell"}>Automated Quality Checks</div>
              <div className={`cell burble ${width >= 800 ? "triple" : ""}`}>Custom Auto-edit bot services</div>
              <div className={"cell double"}>
                <div className={"feature"}>
                  Content Production API for LIve Production
                </div>
                <div className={"feature description "}>
                  (with high performance production cluster)
                </div>
              </div>
              <div className="marker-enterprise">
                <div className={"cell double yellow"}>
                  <div className={"feature"}>Developers SDK</div>
                  <div className={"feature description "}>
                    Build your own content apps on top of a secure design
                    infrastructure.
                  </div>
                </div>
                <div className={"cell double clearbg"}>
                  <div className={"feature"}>Design Insights</div>
                  <div className={"feature description "}>
                    Get detailed creative insights and audits on your assets and
                    designs.
                  </div>
                </div>
                <div className={"cell yellow"}>
                  The best in enterprise SLA, support and security
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"pro-unlock"}>
          <div className={"pro-title"}>Get Pro to unlock</div>
          <div className={"pro-features"}>
            <div className={"feat"}>
              <IntegrateIcon />
              <p>Integrations</p>
            </div>
            <div className={"feat"}>
              <CollabIcon />
              <p>Collaboration</p>
            </div>
            <div className={"feat"}>
              <VidsngifsIcon />
              <p>Videos & GIFs</p>
            </div>
            <div className={"feat"}>
              <QualityIcon />
              <p>Quality Checks</p>
            </div>
            <div className={"feat"}>
              <AutoBotIcon />
              <p>Add on Bots</p>
            </div>
            <div className={"feat"}>
              <TemplateIcon />
              <p>Custom Templates</p>
            </div>
          </div>
        </div>
        <br></br>
        <div className={"enterprise-unlock"}>
          <div className={"enterprise-title"}>Get enterprise to unlock</div>
          <div className={"enterprise-features"}>
            <div className={"feat"}>
              <CustomIcon />
              <p>
                <b>Customizable Features</b>
                <br></br>
                <span>for large design teams and content first companies</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className={"faq-wrap"}>
        <div className={"faqs"}>
          <div className={"titles"}>
            <h1>Frequently Asked<br></br>Questions</h1>
            <p>
              Still haven't found what you're looking for?
              <br></br>
              <a href="mailto:hello@kubric.io?Subject=Hello%20Kubric">Send us a message</a>
            </p>
          </div>
          <div className={"faq-content"}>
            <div className={"faq"}>
              <div className={`ques ${quesno === 1 ? "yellow-q" : ""}`}
                onClick={() => {
                  setquesno(quesno === 1 ? "none" : 1)
                }}
              >
                <span>Which features are included in the free plan?</span>
                <ChevronIcon />
              </div>
              <div className={`ans ${quesno === 1 ? "show-ans" : ""}`}>
                <p>You have access to all features in the free plan.</p>
              </div>
            </div>
            <div className={"faq"}>
              <div className={`ques ${quesno === 2 ? "yellow-q" : ""}`}
                onClick={() => {
                  setquesno(quesno === 2 ? "none" : 2)
                }}
              >
                <span>How can I get started?</span>
                <ChevronIcon />
              </div>
              <div className={`ans ${quesno === 2 ? "show-ans" : ""}`}>
                <p>Sign up for the free plan and you can start using all the features from day one. The best way to experience Kubric is to use it without any limits, and we know that.</p>
                <p>You can always buy more credits or upgrade to the premium plan for better savings with credit packages.</p>
              </div>
            </div>
            <div className={"faq"}>
              <div className={`ques ${quesno === 3 ? "yellow-q" : ""}`}
                onClick={() => {
                  setquesno(quesno === 3 ? "none" : 3)
                }}
              >
                <span>What happens when I run out of credits?</span>
                <ChevronIcon />
              </div>
              <div className={`ans ${quesno === 3 ? "show-ans" : ""}`}>
                <p>When your included credits expire, we will ask you to buy more credits or upgrade to a paid plan to keep using the advanced automation features.</p>
                <p>You can easily buy more credits using a credit card or PayPal. Just go to Settings-Your account to purchase. The credits will be instantly added to your account.</p>
                <p>You can continue using the basic features without any limits.</p>
              </div>
            </div>
            <div className={"faq"}>
              <div className={`ques ${quesno === 4 ? "yellow-q" : ""}`}
                onClick={() => {
                  setquesno(quesno === 4 ? "none" : 4)
                }}
              >
                <span>Is there a limit to the amount of credits I can purchase in the free plan</span>
                <ChevronIcon />
              </div>
              <div className={`ans ${quesno === 4 ? "show-ans" : ""}`}>
                <p>You can buy as many credits as you like and use the free plan for as long as you want.</p>
                <p>The premium plan includes over $100 worth of credits so it is the better deal, but you can choose the right plan based on your usage.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </Layout>
  )
}
